.itemFieldName {
  font-weight: 600;
  color: var(--v-itemLabel-base);
  font-size: 14px;
}

.mainTitle {
  color: var(--v-mainTitleLabel-base);
  font-size: 17px;
  font-weight: 600;
}

.textLabel {
  color: #8898aa;
  font-size: 14px;
}

.v-list-item__content {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.text-error{
  color: #ff5252;
}
.saveButton {
  background-color: var(--v-lightGreen-base) !important;
  color: white !important;
}
.buttonClicked {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white !important;
}
//.v-label--active {
//  background: #e0e0e0;
//}
.whatsappGrey {
  height: 35px !important;
  div{
    height: 35px !important;
  }
  small {
    line-height: 1.8;
  }
  h3 {
    font-size: 15px;
    line-height: 2.2;
  }
}
@media only screen and (max-width: 600px) {
  .whatsappGrey {
    small {
      font-size: 10px;
      line-height: 1;
      display: flex;
    }
    h3 {
      font-size: 14px;
      line-height: 1;
    }
  }
}
.v-main {
  height: 100vh;
  padding-top: 28px !important;
}
// Minimize Header bar height
